<template>
  <div class="article_list_box">
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title"> 软件下载 </span>
      </div>
      <img
        style="width: 100%; height: 100%"
        src="../../assets/img/title_1.jpg"
        alt=""
      />
    </div>
    <div class="article_list">
      <div class="article_list_right">
        <div class="article_list_right_top">
          <div class="article_list_right_tab">首页 > 软件下载 > 软件下载</div>
        </div>
        <div class="article_list_right_List">
          <div
            class="article_list_right_item"
            v-for="item in list"
            :key="item.id"
          >
            <div class="article_list_right_item_img">
              <img
                style="width: 80px; height: 80px"
                :src="http + item.icon + '?imageMogr2/thumbnail/!70p'"
                alt=""
              />
            </div>
            <div class="article_list_right_item_text1">
              <div class="article_list_right_item_text_title">
                {{ item.name }}
              </div>
              <div class="article_list_right_item_text_time">
                更新时间：{{ item.lastModified }}
              </div>
              <div class="article_list_right_item_text_text">
                <span style="font-weight: bold">特点：</span>{{ item.memo }}
              </div>
              <div class="article_list_right_item_text_code">
                MD5校验码：{{ item.md5 }}
              </div>
            </div>
            <div class="article_list_right_item_button">
              <div
                class="article_list_right_item_button_top"
                @click="handle_download(item)"
              >
                立即下载
              </div>
              <div
                class="article_list_right_item_button_bottom"
                @click="handle_instructions(item)"
              >
                使用说明
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="article_list_left2">
        <div class="app_download_title">手机软件下载专区</div>
        <div class="qr" v-for="item in list1" :key="item.id">
          <div v-if="item.url" style="text-align: center">
            <vue-qr
              :size="210"
              :text="
                'http://www.hyfutures.com/#/app_download_tip?android=' +
                item.url
              "
            ></vue-qr>
            <div class="app_download_text">{{ item.name }}</div>
          </div>
          <div v-if="item.sourceUrl" style="text-align: center">
            <vue-qr
              :size="210"
              :text="
                'http://www.hyfutures.com/#/app_download_tip?ios=' +
                item.sourceUrl
              "
            ></vue-qr>
            <div class="app_download_text">{{ item.name }}</div>
          </div>
          <div class="qr_button" @click="handle_instructions(item)">
            使用说明
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      list: [],
      list1: [],
      nav_list: {},
    };
  },
  components: {
    vueQr
  },
  mounted() {
    this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    this.getList();
  },
  methods: {
    handle_instructions(data) {
      if (data.guideUrl && data.guideUrl.length > 0) {
        window.location.href = this.http + data.guideUrl;
      } else {
        window.open(data.guide);
      }
    },
    handle_download(data) {
      if (data.url) {
        window.location.href = this.http + data.url;
      }
      if (data.sourceUrl) {
        window.open(data.sourceUrl);
      }
    },
    getList() {
      this.$axios({
        method: "get",
        url: `/api/web/software/list`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        let arr1 = [];
        let arr2 = [];
        res.data.forEach(item => {
          if (item.type === '01') {
            arr1.push(item);
          } else if (item.type === '02') {
            arr2.push(item);
          }
        })
        this.list = arr1;
        this.list1 = arr2;
        console.log('list1', arr2);
        console.log('list', arr1);
      });
    },
  },
};
</script>
<style scoped>
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.article_list_right_item_button_top {
  width: 112px;
  height: 35px;
  background: #c21d14;
  border-radius: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-bottom: 10px;
  cursor: pointer;
}
.article_list_right_item_button_bottom {
  width: 112px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c21d14;
  border-radius: 5px;
  color: #c21d14;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.article_list_right_item_button {
  margin-left: 28px;
}
.article_list_right_item_text_title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #020202;
}
.article_list_right_item_text_time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #888888;
  line-height: 30px;
}
.article_list_right_item_text_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #020202;
  line-height: 26px;
}
.article_list_right_item_text_code {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 26px;
}
.article_list_right_item_img {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
}
.qr_img {
  width: 210px;
  height: 210px;
  margin: 10px 35px 0px 35px;
}
.qr_button {
  cursor: pointer;
  width: 112px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c21d14;
  border-radius: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c21d14;
  line-height: 35px;
  text-align: center;
  margin: 0 auto;
}
.app_download_text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 50px;
  text-align: center;
}
.qr {
  border: 1px solid #f2f2f2;
  height: auto;
  padding-bottom: 20px;
}
.app_download_title {
  height: 51px;
  width: 280px;
  background: #f2f2f2;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 51px;
  text-align: center;
  margin-top: 46px;
  border-bottom: #c21d14 2px solid;
}
.article_list_right_item_text1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  flex: 1;
}
.article_list_right_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #d3d3d3 dashed;
  min-height: 185px;
  align-items: center;
}
.article_list_right_tab {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 45px;
}
.article_list_right_title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 66px;
  border-bottom: #c21d14 3px solid;
}
.article_list_left2 {
  margin-left: 40px;
  width: 280px;
}
.article_list_right {
  flex: 1;
}
.article_list_right_top {
  border-bottom: #e5e5e5 1px solid;
  display: flex;
  justify-content: space-between;
}
.dian {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
}
.article_list_title {
  width: 280px;
  height: 61px;
  background: #c21d14;
  border-radius: 10px 10px 0px 0px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  padding-left: 29px;
  line-height: 61px;
}
.article_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 1200px;
  margin: 0px auto 94px auto;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.article_list_box {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
</style>
